// ============================
//    10. Widget css start
// ============================


.widget-primary-card.flat-card {
  border-top: none;
  background-color: $primary;
  color: #fff;

  h4,
  h6 {
    color: #fff;
  }

  .row-table {
    &:first-child {
      border-bottom: none;
    }

    >[class*="col-"] {
      border-bottom: none;

      &:first-child {
        background-color: darken($primary, 5%);
        text-align: center;
      }

      display: inline-block;
      vertical-align: middle;

      .row {
        display: flex;
        align-items: center;
      }
    }
  }
}

.widget-purple-card.flat-card {
  @extend .widget-primary-card;
  background-color: $purple;

  .row-table {
    &:first-child {
      border-bottom: none;
    }

    >[class*="col-"] {
      border-bottom: none;

      &:first-child {
        background-color: darken($purple, 5%);
      }
    }
  }
}

.flat-card {
  .row-table {
    display: table;
    align-items: center;
    table-layout: fixed;
    height: 100%;
    width: 100%;
    margin: 0;

    +.row-table {
      border-top: 1px solid var(--bs-border-color);
    }

    .br {
      border-right: 1px solid var(--bs-border-color);
    }

    i {
      font-size: 30px;
    }

    span {
      text-transform: uppercase;
      font-size: 12px;
    }

    h5 {
      display: block;
      margin-bottom: 0.3em;
      margin-right: 0;
    }

    >[class*="col-"] {
      display: inline-block;
      float: none;
      table-layout: fixed;
      vertical-align: middle;
      width: calc(100% / 3);

      .row {
        display: flex;
        align-items: center;
      }
    }

    @include media-breakpoint-down(sm) {
      display: block;

      >[class*="col-"] {
        display: inline-block;
        width: calc(50% - 2px) !important;
        border-bottom: 1px solid var(--bs-border-color);
      }
    }
  }

  &[class*="widget-"] {
    .row-table {
      display: flex;
    }
  }
}


.statustic-card {
  .card-body {
    position: relative;

    .progress {
      position: absolute;
      bottom: 0;
      width: 100%;
      left: 0;
      height: 5px;
    }
  }
}

.widget-visitor-card {
  overflow: hidden;
  padding: 10px 0;

  i,
  svg {
    font-size: 80px;
    color: #fff;
    width: 80px;
    height: 80px;
    position: absolute;
    bottom: -10px;
    opacity: 0.3;
    left: -10px;
    transform: rotate(15deg);
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    svg {
      transform: rotate(0deg) scale(1.4);
      opacity: 0.5;
    }
  }
}


.feed-card {
  h6 {
    margin-top: 7px;
    font-size: 14px;
    transition: all 0.3s ease-in-out;

    >span {
      font-size: 11px;
    }

    &:hover {
      color: $primary;
    }
  }

  .feed-icon {
    border-radius: 50%;
    display: block;
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 8px 9px;
  }

  .card-body {
    position: relative;
    padding-left: 12px !important;

    &.p-t-0.p-b-0 {
      padding-left: 12px;
    }

    .border-feed {
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
      transition: all 0.3s ease-in-out;
      position: relative;

      i {
        position: absolute;
        top: calc(50% - 20px);
        left: 0;
        right: 0;
        text-align: center;
        color: #fff;
        transition: all 0.3s ease-in-out;

        &.material-icons-two-tone {
          background-color: #fff;
        }
      }
    }
  }

  &:hover {

    @each $color,
    $value in $theme-colors {
      .border-feed.bg-#{$color} {
        background-color: transparentize($value, 0.7) !important;

        i {
          color: $value;

          &.material-icons-two-tone {
            background-color: $value;
          }
        }
      }
    }
  }
}

.proj-t-card {
  position: relative;
  overflow: hidden;

  .pt-badge {
    margin-bottom: 0;
    display: inline-block;
    padding: 60px 50px 20px 20px;
    border-radius: 50%;
    position: absolute;
    top: -45px;
    right: -35px;
    color: #fff;
    transition: all 0.3s ease-in-out;
  }

  @each $color,
  $value in $theme-colors {
    &.card-border-#{$color} {
      border-top-color: $value !important;
    }

    .pt-badge.bg-light-#{$color} {
      background: $value;
    }
  }
}

.ticket-card {
  .lbl-card {
    border-radius: 50px;
    padding: 5px 15px;
    display: inline-block;
  }
}


.trafic-card {
  .progress {
    height: 7px;
  }
}

.user-activity-card {
  .u-img {
    position: relative;

    .cover-img {
      width: 40px;
      height: 40px;
    }

    .profile-img {
      width: 20px;
      height: 20px;
      position: absolute;
      bottom: 0;
      right: -5px;
    }
  }
}

.user-Messages-card {
  .text-time {
    font-size: 11px;
  }

  .u-img {
    position: relative;

    .profile-img {
      width: 40px;
      height: 40px;
    }

    .tot-msg {
      width: 15px;
      height: 15px;
      position: absolute;
      bottom: 0;
      right: -5px;
      background-color: $danger;
      color: #fff;
      font-size: 10px;
      border-radius: 50%;
      text-align: center;
    }
  }
}

.chat-card {
  .msg {
    margin-bottom: 5px;
    display: inline-block;
    padding: 10px;
    position: relative;

    img {
      width: 60px;
      border-radius: 5px;
      margin-bottom: 5px;
      margin-top: 5px;
      margin-right: 10px;
    }
  }

  .received-chat {
    .msg {
      background: lighten($primary, 30%);
      border-radius: 0 5px 5px 5px;

      &:after {
        content: "";
        position: absolute;
        left: -7px;
        top: -7px;
        transform: rotate(45deg);
        border: 7px solid transparent;
        border-bottom-color: lighten($primary, 30%);
      }
    }
  }

  .send-chat {
    text-align: right;

    .msg {
      background: $primary;
      color: #fff;
      border-radius: 5px 0 5px 5px;

      &:after {
        content: "";
        position: absolute;
        right: -7px;
        top: -7px;
        transform: rotate(45deg);
        border: 7px solid transparent;
        border-right-color: $primary;
      }
    }
  }
}

.latest-update-card {
  .card-body {
    padding-top: 0;

    .latest-update-box {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        background: #ebebeb;
        height: 100%;
        width: 3px;
        top: 0;
        left: 82px;
        z-index: 1;
      }

      .update-meta {
        z-index: 2;

        .update-icon {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          text-align: center;
          padding: 7px 8px;
          font-size: 16px;
          color: #fff;

          &.ring {
            border-width: 3px;
            width: 15px;
            height: 15px;
            padding: 0;
            display: block;
            background: #fff;
          }
        }

        i.update-icon {
          margin-left: 4px;
          margin-right: 4px;
        }

        img.update-icon {
          padding: 0;
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}

.latest-posts-card {
  a {
    transition: all 0.3s ease-in-out;

    &:hover h6 {
      color: $primary;
    }

    ~p {
      font-size: 11px;
    }
  }

  .latest-posts-img {
    width: 90px;
    height: 80px;
  }
}

.task-card {
  .task-list {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      background: var(--bs-border-color);
      height: 100%;
      width: 2px;
      top: 0;
      left: 10px;
      z-index: 1;
    }

    &:before {
      content: "";
      position: absolute;
      background: var(--bs-border-color);
      height: 15px;
      width: 15px;
      bottom: -14px;
      left: 3px;
      z-index: 2;
      border-radius: 50%;
    }

    li {
      margin-bottom: 30px;
      padding-left: 30px;
      position: relative;

      .task-icon {
        position: absolute;
        left: 3px;
        top: 4px;
        border-radius: 50%;
        padding: 2px;
        color: #fff;
        min-width: 15px;
        min-height: 15px;
        z-index: 2;
      }
    }
  }
}


.review-card {
  .review-block {
    .cust-img {
      width: 50px;
      height: 50px;
    }

    >div {
      padding-top: 25px;
      padding-bottom: 25px;
      margin-left: 0;
      margin-right: 0;
      padding-left: 5px;
      padding-right: 5px;

      &:hover {
        background-color: transparentize($primary, 0.9);
      }
    }
  }
}

.latest-activity-card {
  .card-body {
    padding-top: 0;

    .latest-update-box {
      position: relative;

      .update-meta {
        z-index: 2;
        min-width: 170px;

        .update-icon {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          text-align: center;
          padding: 12px 13px;
          font-size: 16px;
          color: #fff;
          margin-left: 10px;
        }

        @media only screen and (max-width: 575px) {
          z-index: 2;
          min-width: 100%;
          text-align: left !important;
          margin-bottom: 15px;
          border-top: 1px solid #f1f1f1;
          padding-top: 15px;
        }
      }
    }
  }
}
.total-card {
  .text-start {
      h4 {
          color: #fff;
          font-weight: 400;
          margin-bottom: 5px;
      }

      p {
          color: #fff;
          font-size: 15px;
      }
  }
  overflow: hidden;
}

.client-map {
  img {
      vertical-align: top;
      width: 90px;
  }

  .client-detail {
      margin-bottom: 20px;

      img {
          border-radius: 5px;
      }
  }

  .client-profile {
      float: left;
  }

  .client-contain {
      display: inline-block;
      margin-left: 20px;

      h5 {
          display: block;
          text-transform: capitalize;
          font-weight: 600;
          font-size: 18px;
          padding-bottom: 10px;
      }
  }

  .client-card-box {
      border: 1px solid var(--bs-border-color);
      border-radius: 5px;

      .client-border {
          border-right: 1px solid var(--bs-border-color);
      }

      .client-border-card {
          border-top: 1px solid var(--bs-border-color);
      }
  }
}

.mrr-card {
  position: relative;

  .bottom-chart {
      bottom: 0;
  }
}

.statistics-card-1 {
  .card-body {
    position: relative;
    overflow: hidden;
    > * {
      position: relative;
      z-index: 5;
    }
    .img-bg {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
    }
  }
}

.earning-card {
  .nav {
    background: rgba(255, 255, 255, 0.3);
    border-radius: var(--bs-border-radius);
    .nav-link {
      color: var(--pc-heading-color);
      margin: -8px 0;
      padding-top: 16px;
      padding-bottom: 16px;
      @include media-breakpoint-down(sm) {
        padding: 14px 6px;
      }
    }
    &.nav-pills {
      .nav-link.active,
      .show > .nav-link {
        box-shadow: var(--pc-card-box-shadow);
        background: var(--bs-success-bg-subtle);
        color: var(--bs-success);
      }
    }
  }
}

.table-card .card-body,
.table-body.card-body {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;

  .table {
    > thead > tr > th {
      border-top: 0;
    }

    tr {
      td,
      th {
        &:first-child {
          padding-left: 25px;
          @include media-breakpoint-down(sm) {
            padding-left: 20px;
          }
        }

        &:last-child {
          padding-right: 25px;
          @include media-breakpoint-down(sm) {
            padding-right: 20px;
          }
        }
      }
    }

    &.without-header {
      tr {
        &:first-child {
          td {
            border-top: none;
          }
        }
      }
    }
  }
}

.latest-activity-card {
  .card-body {
    padding-top: 0;

    .latest-update-box {
      position: relative;

      .p-t-20 {
        @include media-breakpoint-down(sm) {
          padding-top: 0;
        }
      }

      .p-b-30 {
        @include media-breakpoint-down(sm) {
          padding-bottom: 15px;
        }
      }

      .update-meta {
        z-index: 2;
        min-width: 170px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        &:after {
          content: '';
          position: absolute;
          border-left: 2px dashed var(--bs-border-color);
          bottom: -10px;
          width: 2px;
          top: -20px;
          right: 32px;
          z-index: 1;
        }
        .update-icon {
          width: 40px;
          height: 40px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          text-align: center;
          font-size: 16px;
          background: var(--bs-card-bg);
          margin-left: 10px;
          position: relative;
          z-index: 5;
          i {
            font-size: 24px;
          }
        }
        @media only screen and (max-width: 575px) {
          z-index: 2;
          min-width: 100%;
          flex-direction: row-reverse;
          justify-content: flex-end;
          margin-bottom: 6px;
          border-top: 1px solid #f1f1f1;
          padding-top: 15px;
          &::after {
            display: none;
          }
          .update-icon {
            margin-left: 0;
            margin-right: 10px;
          }
        }
      }

      .row {
        @include media-breakpoint-down(sm) {
          margin-inline: 0;
        }
        > div {
          @include media-breakpoint-down(sm) {
            padding-inline: 0;
          }
        }
        &:first-child {
          .update-meta {
            border-top: 0;
            padding-top: 0;
          }
        }
      }

    }
  }
}

// ============================
//    10. Widget css end
// ============================